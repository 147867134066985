@charset "utf-8";
@import "./include-media.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans&display=swap");


* {
  font-family: "Montserrat";
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.section {
  width: 100vw;
  margin-top: 5vh;
  height: auto;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.primary-btn {
  font-family: "Open Sans";
  margin: 10px;
  padding: 10px;
  // background-color: black;
  opacity: 0.6;
  color: black;
  border: 0px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    // background: rgb(141, 141, 141);
    color: black;
    font-weight: bold;
  }
}

//NAVBAR CSS START
.navbar-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
    font-weight: 600;
  }
  
  .links-wrapper {
    button {
      text-decoration: none;
      padding: 12px;
      font-family: "Open Sans";
      opacity: 0.6;
      transition: all 0.2s ease-in-out;
      font-size: 15px;
      background-color: transparent;
      outline: none;
      border: 0px;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .active {
      color: black;
    }
  }
}
//NAVBAR CSS END

// HEADER CSS START
.header-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans";

  h2 {
    font-size: 1rem;
    text-align: center;
    @include media(">=phone", "<lgphone") {
      font-size: 14px;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 16px;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 16px;
    }
  }
  .heading-wrapper {
    h1 {
      font-size: 3rem;
      text-align: center;
      line-height: 20px;
      @include media(">=phone", "<lgphone") {
        font-size: 1.5rem;
        line-height: 10px;
      }
      @include media(">=lgphone", "<tablet") {
        font-size: 1.5rem;
        line-height: 10px;
      }
      @include media(">=tablet", "<desktop") {
        font-size: 2.5rem;
      }
    }
  }
  p {
    width: 50%;
    text-align: center;
    overflow: hidden;
    @include media(">=phone", "<lgphone") {
      font-size: 12px;
      width: 100%;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 12px;
      width: 100%;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 14px;
      width: 100%;
    }
  }
}

//HEADER CSS END

// ABOUT CSS START
.about-section {
  width: 70vw;
  margin-top: 10vh;
  display: flex;
  justify-content: space-between;
  @include media(">=phone", "<lgphone") {
    flex-direction: column-reverse;
  }
  @include media(">=lgphone", "<tablet") {
    flex-direction: column-reverse;
  }
  @include media(">=tablet", "<desktop") {
    flex-direction: column-reverse;
    align-items: center;
  }
  .content {
    width: 60%;
    @include media(">=phone", "<lgphone") {
      width: 100%;
    }
    @include media(">=lgphone", "<tablet") {
      width: 100%;
    }
    @include media(">=tablet", "<desktop") {
      width: 100%;
    }
    p {
      font-size: .89rem;
      @include media(">=tablet", "<desktop") {
        text-align: center;
      }
    }
  }
  .image-wrapper {
    display: flex;
    align-items: right;
    justify-content: center;
    overflow: hidden;
    @include media(">=phone", "<lgphone") {
      width: 100%;
    }
    @include media(">=lgphone", "<tablet") {
      width: 100%;
    }
    @include media(">=tablet", "<desktop") {
      width: 100%;
    }
  }

.about-me-image{
    height: 100%;
    width: 50%;
    border-radius: 2px;
    
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    &:hover {
      -webkit-filter: grayscale(1%); /* Safari 6.0 - 9.0 */
    filter: grayscale(1%);
    }

  }
}

.paragraph {
  line-height: 1.8em;
}
// ABOUT CSS END


//FOOTER CSS START
.footer-container {
  width: 80vw;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 3rem;
    line-height: 20px;
  }
  h2 {
    font-size: 1.5rem;
    overflow: hidden;
    @include media(">=phone", "<lgphone") {
      display: none;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 14px;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 1.2rem;
    }
  }
  .email-link {
    font-size: 1.5rem;
    color: black;
  }
//   .social-icons1 {
//     margin-top: 20px;
//     display: flex;
//     flex-direction: row;    
//     img {
//       height: 2rem;
//       margin: 10px;
//     }
//  }
//   .social-icons  {
//     color: white;
//     border: 0px;
//     transition: all 0.2s ease-in-out;
//     text-decoration: none;
//     border-radius: 5px;
//     cursor: pointer;
//     &:hover {
//       transform: scale(1.1);
//       background: rgb(235, 153, 167);
//       color: black;
//       font-weight: bold;
//     }
//   }

  // span {
  //   margin-top: 10px;
  // }
  // icon {
  //   color: red;
  // }
}

//FOOTER CSS END

//SKILSS CSS START
.skills-container {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 1rem;
    line-height: 20px;
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 50px;
    grid-gap: 20px;
    @include media(">=phone", "<lgphone") {
      grid-template-columns: 1fr;
    }
    @include media(">=lgphone", "<tablet") {
      grid-template-columns: 1fr;
    }
    @include media(">=tablet", "<desktop") {
      grid-template-columns: 1fr 1fr;
    }
    .skill {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 10vw;
      img {
        height: 3rem;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          // filter: grayscale(100%);
          filter: brightness(30%);
      }
      p {
        font-size: 14px;
      }
    }
  }
}
// SKILLS CSS END

//RESUME CSS START
.model{
  // width: 100vw;
  // height: 100vw;
  // display: flex;
  // margin-top: 10%;
  // background: pink;
  display: flex;
  justify-content: center;

}

// .modelContent{
//   display: flex;
//   background: yellow;
// }

.resume {
  height:100%;
  width: 80%;
  // padding: 12%;
  margin-right: 15%;
  margin-left: 10%;
  margin-bottom: 1%;
}

.view-resume {
  margin: 10px;
  padding: 10px;
  font-family: "Open Sans";
  font-size: 1rem;
  background-color: black;
  color: white;
  border: 0px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    background: rgb(235, 153, 167);
    color: black;
    font-weight: bold;
  }
}

.close-resume-button{
  font-size: 1.3rem;
  border-radius: 1px;
  // background-color: black;
  color: black;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    background: rgb(177, 175, 175);
    color: black;
    font-weight: bold;
  }
}

.button-wrap{
  display: flex;
  justify-content: flex-start;
  margin-left: 12%;
  // background: aquamarine;
}

//RESUME CSS END 

//WORK CSS START
.work-wrapper {
  width: 70vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 3rem;
    line-height: 20px;
  }
  .grid {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 10px;
    @include media(">=phone", "<lgphone") {
      grid-template-columns: 1fr;
    }
    @include media(">=lgphone", "<tablet") {
      grid-template-columns: 1fr;
    }
    @include media(">=tablet", "<desktop") {
      grid-template-columns: 1fr 1fr;
    }
  }
}

//WORK CSS END

//CARD CSS START
// .card {
//   width: 100%;
//   height: 250px;
//   border-radius: 2%;
//   overflow: hidden !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
//   -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
//   filter: grayscale(100%);
//   &:hover {
//     -webkit-filter: grayscale(1%); /* Safari 6.0 - 9.0 */
//   filter: grayscale(1%);
//   }


//   .content {
//     font-family: "Open Sans";
//     text-align: center;
//     padding: 10px;
//     opacity: 0;
//     transition: all 500ms ease-in-out;
//     transform: translate(0, 20px);
//     cursor: pointer;
//     h1 {
//       font-size: 19px;
//       color: white;
//     }
//     p {
//       font-size: 14px;
//       color: white;
//       margin-bottom: 20px;
//       padding: 10px;
//       font-weight: bold;
//       background: rgba(0, 0, 0, 0.7);
//       border-radius: 2px;
//     }

//     .btn {
//       background-color: black;
//       color: white;
//       padding: 10px;
//       margin: 10px;
//       text-decoration: none;
//       border-radius: 2px;
//       &:hover {
//         transition: all 0.2s ease-in-out;
//         background: white;
//         color: black;
//         font-weight: bold;
//       }
//     }
//     &:hover {
//       opacity: 1;
//       transform: translate(0, 0px);
//     }
//   }
// }
//CARD CSS END

//CARD CSS START
.card {
  width: 100%;
  height: 40vh;
  border-radius: .5%;
  background-size: cover;  
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  &:hover {
    -webkit-filter: grayscale(1%); /* Safari 6.0 - 9.0 */
  filter: grayscale(1%);
  }

  .content {
    font-family: "Open Sans";
    text-align: center;
    padding: 10px;
    opacity: 0;
    transition: all 500ms ease-in-out;
    transform: translate(0, 20px);
    cursor: pointer;
    h1 {
      font-size: 19px;
      color: white;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 2px;
      width: 100%;
    }
    p {
      font-size: 14px;
      color: white;
      margin-bottom: 20px;
      padding: 10px;
      font-weight: bold;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 2px;
    }

    .btn {
      margin: 10px;
      padding: 10px;
      background-color: black;
      color: white;
      border: 0px;
      transition: all 0.2s ease-in-out;
      text-decoration: none;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        background: #D2CFCE;
        color: black;
        font-weight: bold;
      }
    }
    
    // .btn {
    //   background-color: black;
    //   color: white;
    //   padding: 10px;
    //   margin: 10px;
    //   text-decoration: none;
    //   border-radius: 2px;
    //   &:hover {
    //     transition: all 0.2s ease-in-out;
    //     background: white;
    //     color: black;
    //     font-weight: bold;
    //   }
    // }
    &:hover {
      opacity: 1;
      transform: translate(0, 0px);
    }
  }
}
//CARD CSS END