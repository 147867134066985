.App {
  text-align: center;
}

.social-container{
  /* padding: 10px; */
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  /* justify-content: end; */
  width: 99%;
  position: fixed;

}
.social-images{
 width: 1.2vw;
}
.social-icons1{
padding: 10px;
}
.social-icons1:hover {
  transform: scale(2);
}

/* .social-icons{
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  cursor: none;
}

.container {
/* background: black; */
/* min-height: 100vh; */
/* background-color: pink; */
margin-top: -3%;

}

.cursor {
  position: fixed;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events:none;
}

.cursors .cursor:nth-child(1) {
  background-color: #000000;
  z-index: 100002;
}
.cursors .cursor:nth-child(2) {
  background-color: #eb66c3;
  z-index: 100001;
  height: 9px;
  width: 9px;
}

/* BURGER CSS */

 /* body
 {
   margin: 0;
   padding: 0;
   background: white;
   color: #232323;
   font-family: "Avenir Next", "Avenir", sans-serif;
   
 } */
 
 #menuToggle
 {
   display: block;
   position: relative;
   top: 50px;
   left: -200px;

   
   z-index: 1;
   
   -webkit-user-select: none;
   user-select: none;
 }

 button{
  text-decoration: none;
  padding: 8px;
  font-family: "Open Sans";
  transition: all 0.2s ease-in-out;
  font-size: 15px;
  background-color: transparent;
  outline: none;
  border: 0px;
  cursor: pointer;
 }

 #menuToggle button:hover
 {
   color: #8fda06;
 }
 
 #menuToggle input
 {
   display: block;
   width: 40px;
   height: 32px;
   position: absolute;
   top: -7px;
   left: -5px;
   
   cursor: pointer;
   
   opacity: 0; /* hide this */
   z-index: 2; /* and place it over the hamburger */
   
   -webkit-touch-callout: none;
 }
 
 
 #menuToggle span
 {
   display: block;
   width: 33px;
   height: 4px;
   margin-bottom: 5px;
   position: relative;
   
   background: #e779bd;
   border-radius: 3px;
   
   z-index: 1;
   
   transform-origin: 4px 0px;
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease;
 }
 
 #menuToggle span:first-child
 {
   transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
   transform-origin: 0% 100%;
 }
 

 #menuToggle input:checked ~ span
 {
   opacity: 1;
   transform: rotate(45deg) translate(-2px, -1px);
   background: #e779bd;
 }
 

 #menuToggle input:checked ~ span:nth-last-child(3)
 {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);

 }
 

 #menuToggle input:checked ~ span:nth-last-child(2)
 {
   transform: rotate(-45deg) translate(0, -1px);
 }
 

 #menu
 {
   position: absolute;
   width: 300px;
   margin: -100px 0 0 -100px;
   padding: 50px;
   padding-top: 125px;
   
   background:rgb(237, 237, 237, 0.6);
   list-style-type: none;
   -webkit-font-smoothing: antialiased;
   /* to stop flickering of text in safari */
   
   transform-origin: 0% 0%;
   transform: translate(-100%, 0);
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 }

 @media screen and (max-width: 767px) {
    #menu
        {
        position: absolute;
        width: 100px;
        margin: -70px -70px 0px -100px;
        padding: 100px;
        padding-top: 125px;
        
        background:rgb(237, 237, 237, 0.6);
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        }
   } 
 
 #menu li
 {
   padding: 10px 0;
   padding-left: 20%;
   font-size: 22px;
   
 }

 @media screen and (max-width: 767px) {
   #menu li
    {
      padding: 0px 0;
      padding-left: 0%;
      font-size: 20px;      
    }
  } 



#menu li:active{
    font-weight: bold;
    color: #e779bd;

  }

 #menuToggle input:checked ~ ul
 {
   transform: none;
 }

/* BURGER CSS END  */